/* You can add global styles to this file, and also import other style files */

.text-red-bold {
    color: #f00;
    font-weight: bold;
}

.ng-select-active .ng-select-container {
    border: 2px solid #f00;
}

th {
    font-weight: bold !important;
}

.label {
    font-size: 10px !important;
}

.label-text-grey{
  color: #3f4a51 !important;
}

/* .swal2-popup {
    width: 1000px !important;
} */

// .swal2-html-container {
//     text-align: left !important;
// }

ol > li::marker {
    font-weight: bold;
}

#mainDatatables_length > label > select {
    width: 100px !important;
    text-align: center !important;
}

.dataTables_empty {
    display: none;
}

table.table-bordered.dataTable tbody td a {
    color: black !important;
}

ngb-modal-backdrop {
    z-index: 1050 !important;
}

.autosize {
    height: auto !important;
    min-height: 34px;
    max-height: 9999px;
    overflow: hidden;
}

.row-border {
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px
}

.origin-link{
  font-size: 16px;
}
